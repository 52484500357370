.hs-form {
    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .hs-fieldtype-text > label,
    .hs-fieldtype-textarea > label,
    .hs-fieldtype-phonenumber > label {
        .embedded-form__footer & {
            font-size: 12px;
        }
    }

    .hs-form-field label:not(.hs-error-msg) {
        display: flex !important;
        margin-bottom: 6px;
    }

    .hs-form-booleancheckbox label:not(.hs-error-msg) {
        margin-bottom: 0;
    }

    .hs-field-desc,
    .hs-error-msg,
    .hs-error-msgs {
        font-size: 12px;
    }

    .hs-form-field {
        margin-bottom: 20px;
    }

    .hs-form-field.hs-fieldtype-booleancheckbox {
        .embedded-form__footer & {
            font-size: 12px;
        }
    }

    .hs-form-field.hs-fieldtype-textarea textarea,
    .hs-form-field.hs-fieldtype-text input,
    .hs-form-field.hs-fieldtype-phonenumber input {
        width: 100%;
        font-size: 16px;
        height: 54px;
        padding: 0 20px;
        background-color: theme('colors.grey.light');
        color: theme('colors.blue.dark');
        border: 2px solid theme('colors.grey.light');
        border-radius: 6px;

        &.error {
            border: 2px solid theme('colors.red') !important;
        }

        .embedded-form__footer & {
            background: #fff;
            border-color: transparent;
            @screen l {
                font-size: 12px;
                height: 44px;
            }
        }
    }

    .hs-form-field.hs-fieldtype-text input {

    }

    .hs-form-field.hs-fieldtype-textarea textarea {
        height: 200px !important;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    /*
    .hs-form-field.hs-fieldtype-select {
        width: 100%;

        > .input {
            width: 100%;
            height: 56px;
            position: relative;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: 16px;
            }
        }

        select {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            font-size: 16px;
            height: 56px;
            padding: 0 16px;
            background-color: transparent;
            cursor: pointer;
            border: 1px solid var(--color-divider);
            border-radius: 4px;

            &.is-placeholder {
                opacity: 0.6;
            }

            &.error {
                border: 1px solid var(--color-error);
            }
        }
    }
    
     */

    .legal-consent-container {
        margin-top: 8px;
    }

    .hs-form-booleancheckbox-display {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        user-select: none;

        input {
            flex: none;
            margin: 3px 10px 0 0;
        }

        > span {
            flex: 1 1 auto;

            p, span {
                display: inline;
            }
        }
    }

    .hs-richtext {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    ::placeholder {
        color: currentColor;
        opacity: 0.6;
    }

    .hs_recaptcha.field {
        margin-bottom: 40px;
    }

    .hs-button {
        @apply btn btn--has-bg btn--primary cursor-pointer mt-8;
    }

    /*
    .hs-button {
        cursor: pointer;
        border: 1px solid black;
        font-size: 12px;
        line-height: 20px;
        padding: 5px 12px;
        text-transform: uppercase;
        font-family: theme('fontFamily.roobert-mono');
        letter-spacing: 0.5px;
        border-radius: 100px;
        transition: color 0.3s, background-color 0.3s;
        &:hover {
            background-color: var(--color-foreground);
            color: var(--color-background);
        }
        @screen l {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 1.5px;
            padding: 10px 16px;
        }
    }
    
     */

    .hs-error-msg,
    .hs-error-msgs {
        color: theme('colors.red');
    }

    .hs-error-msgs {
        margin: 20px 0 30px;
    }

    .hs-form-field .hs-error-msgs {
        margin: 8px 0 0;
    }

}
