@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    //.btn {
    //    @apply bg-blue m:bg-red;
    //}
    
    .body-lg {
        @apply text-18 leading-1_3 l:text-20;
    }
    .body-md {
        @apply text-16 leading-1_3;
    }
    .body-sm {
        @apply text-14 leading-1_3;
    }
    .body-xs {
        @apply text-12 leading-1_3;
    }

    .label-md {
        @apply text-16 leading-1_3;
    }
    .label-sm {
        @apply text-14 leading-1_3;
    }
    
    .h1,
    .h2,
    .h3,
    .h4,
    .h5 {
        @apply font-bizbrains font-bold;
    }
    
    
    .h1 {
        @apply text-36 leading-1_2 l:text-64;
    }
    .h2 {
        @apply text-32 leading-1_2 l:text-48;
    }
    .h3 {
        @apply text-28 leading-1_2 l:text-48;
    }
    .h4 {
        @apply text-22 leading-1_2 l:text-32;
    }
    .h5 {
        @apply text-18 leading-1_2 l:text-20;
    }
    
    .grapper {
        @apply wrapper grid gutters-x;
    }
    
    .variable-ratio {
        padding-bottom: calc((1/var(--ratio-s))*100%);
        
        @screen m {
            padding-bottom: calc((1/var(--ratio-m, --ratio-s))*100%);
        }
        @screen l {
            padding-bottom: calc((1/var(--ratio-l, --ratio-m))*100%);
        }
    }
    
    .hit {
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            min-width: 44px;
            min-height: 44px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .top-padding {
        @apply pt-102 m:pt-140;
    }
    
    .btn {
        @apply inline-block text-15 leading-1 text-center font-medium;
        
        &--has-bg {
            @apply rounded-[6px] py-13 px-20 transition-colors duration-200;
        }
        
        &--primary {
            @apply border-1 border-yellow bg-yellow hover:bg-yellow-light hover:border-yellow-light text-blue-dark;
        }
        &--secondary {
            @apply border-1 border-current bg-white hover:bg-grey-lightest text-blue-dark;
        }
    }
    
    .heading-w-highlight,
    .text-w-highlight {
        i,em {
            @apply text-blue;
        }
    }
    
    .features-list {
        li {
            padding-left: 24px;
            position: relative;
            
            &:before {
                content: '';
                position: absolute;
                display: block;
                width: 14px;
                height: 9px;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.75L6 9.75L14.5 1.25' stroke='%236694FF' stroke-width='2'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: contain;
                left: 0;
                top: 0.45em;
            }
        }
    }
    
    .information-button {
        span:first-child {
            @apply block relative z-2 size-[17px];
            
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.03 6.89H8.96V12.09H8.03V6.89ZM8 6.15V5H8.99V6.15H8Z' fill='%2374757D'/%3E%3Ccircle cx='8.5' cy='8.5' r='8' stroke='%2374757D'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: contain;
        }
        
        &:before {
            @apply absolute center block bg-grey-lightest size-[33px] rounded-[8px] transition-opacity duration-100;
            content: '';
            opacity: 0;
        }
        
        &:focus:before,
        &:hover:before {
            opacity: 1;
        }

        &:focus + div,
        &:hover + div {
            opacity: 1;
        }
    }
    
    .information-box {
        transition-property: opacity;
        transition-duration: 0.1s;
        transition-timing-function: ease;
        
        &:after {
            @apply absolute block w-[14px] h-[12px] center-x; 
            
            content: '';
            top: calc(100%-1px);
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 12L0.0717975 -1.30507e-06L13.9282 -9.36995e-08L7 12Z' fill='%2330323F'/%3E%3C/svg%3E%0A");
            background-size: contain;
        }
    }
    
    .login-link-menu-item {
        @screen m {
            padding-left: 15px;
            &:before {
                content: '';
                display: block;
                position: absolute;
                background-color: theme('colors.grey.light');
                height: 12px;
                width: 2px;
                border-radius: 2px;
                top: 17px;
                left: 7px;
            }
        }
    }
    
    .feature-point-heading {
        @apply relative pl-22;
        
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: theme('colors.blue.DEFAULT');
            border-radius: 10px;
            left: 0;
            top: 0.36em;
        }
    }
    
    .map-marker {
        @apply bg-blue size-[16px] rounded-10px;
    }
    
    .video-overlay {
        
    }
    
}
