.wysiwyg {
    h2 {
        @apply h2 mb-16 l:mb-24 mt-48 l:mt-72 first:mt-0;
    }

    h3 {
        @apply h4 mb-8 l:mb-16 mt-32 l:mt-48 first:mt-0;
    }
    
    a {
        @apply underline hover:no-underline;
    }

    p, ul, ol, li {
        margin-bottom: 1.3em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul, ol {
        li {
            padding-left: 25px;
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 3px;
                height: 3px;
                border-radius: 5px;
                position: absolute;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ul li::before {
        top: 0.6em;
        left: 11px;
        background-color: currentColor;
    }

    ol {
        counter-reset: list_counter;
    }

    ol li::before {
        top: 0;
        left: 0;
        text-align: right;
        width: 30px;
        color: currentColor;
        counter-increment: list_counter;
        content: counter(list_counter) '.';
    }

    ul > li > ul,
    ol > li > ol {
        margin: 1.5em 0;
    }
}
