.page-header {
    transition-property: background, transform;
    transition-duration: 200ms, 500ms;
    transition-delay: 200ms, 0ms;
    transition-timing-function: linear, cubic-bezier(0.215, 0.610, 0.355, 1.000);

    &.is-hidden:not(.is-locked) {
        transform: translate(0, -101%);
        transition-duration: 200ms, 300ms;
    }

    &.is-locked {
        transition-delay: 0ms, 0ms;
    }

    &.is-forced {
        transition-duration: 0ms !important;
        transition-delay: 0ms !important;
    }
    
    
    &__logo-text {
        @screen m {
            transition-property: transform;
            transition-duration: 0.4s;
            transition-delay: 0.3s;
            
            .is-collapsed & {
                transform: translateY(-60px);
            }
        }
    }
    
    &__logo-symbol {
        transform: translateY(-75px);
        display: none;
        
        @screen m {
            display: block;
            transition-property: transform;
            transition-duration: 0.4s;
            
            .is-collapsed & {
                transform: translateY(0px);
                transition-delay: 0.3s;
            }
        } 
    }
}
